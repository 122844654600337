<template>
  <section>
    <a-divider class="dividerTitle text-outline">
      {{ $t("pages.profile.title") }}
    </a-divider>
    <div :style="{ 'margin-left': '20px', 'margin-right': '20px' }">
      <a-form :form="form" class="profile-form" @submit="handleSubmit">
        <div
          class="subtitle text-outline"
          :style="{
            'margin-left': 'auto !important',
            'margin-right': 'auto !important',
            width: 'fit-content',
          }"
        >
          {{ $t("pages.profile.subtitle") }}
        </div>
        <a-form-item
          v-bind="formItemLayout"
          label="E-mail"
          class="text-outline"
        >
          <span class="text-outline">
            {{ getProfile.email }}
          </span>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" class="text-outline">
          <span slot="label">{{ $t("ui.FirstName") }}&nbsp;</span>
          <a-input
            v-decorator="[
              'firstname',
              {
                rules: [
                  {
                    required: true,
                    message: $t('ui.ProvideFirstName'),
                    whitespace: true,
                  },
                ],
                initialValue: getProfile.firstname,
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" class="text-outline">
          <span slot="label">{{ $t("ui.SecondName") }}&nbsp;</span>
          <a-input
            v-decorator="[
              'secondname',
              {
                rules: [
                  {
                    required: true,
                    message: $t('ui.ProvideSecondName'),
                    whitespace: true,
                  },
                ],
                initialValue: getProfile.secondname,
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" class="text-outline">
          <span slot="label">{{ $t("ui.gender") }}&nbsp;</span>
          <a-select
            v-decorator="[
              'gender',
              {
                rules: [
                  { required: true, message: $t('ui.ProvideYourGender') },
                ],
                initialValue: getProfile.gender
                  ? $t('ui.GenderFemale')
                  : $t('ui.GenderMale'),
              },
            ]"
            :placeholder="$t('ui.PleaseProvideYourGender')"
          >
            <a-select-option value="0">
              {{ $t("ui.GenderMale") }}
            </a-select-option>
            <a-select-option value="1">
              {{ $t("ui.GenderFemale") }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout"
          :label="$t('ui.BirthDate')"
          class="text-outline"
        >
          <a-select defaultValue="1" style="width: 70px" v-model="dayVal">
            <a-select-option
              v-for="item in 31"
              :key="item + 'year'"
              :value="31 - item + 1"
              >{{ 31 - item + 1 }}</a-select-option
            >
          </a-select>
          <a-select defaultValue="1" style="width: 70px" v-model="monthVal">
            <a-select-option
              v-for="item in 12"
              :key="item + 'year'"
              :value="12 - item + 1"
              >{{ 12 - item + 1 }}</a-select-option
            >
          </a-select>
          <a-select defaultValue="1970" style="width: 90px" v-model="yearVal">
            <a-select-option
              v-for="item in 100"
              :key="item + 'year'"
              :value="2010 - item"
              >{{ 2010 - item }}</a-select-option
            >
          </a-select>
          <!-- <a-date-picker
            v-decorator="[
              'dateofbirth',
              {
                rules: [{ required: true, message: $t('ui.ProvideBirthDate') }],
                initialValue: getTime(getProfile.dateofbirth)
              }
            ]"
            style="width: 100%"
          /> -->
        </a-form-item>
        <a-form-item v-bind="tailFormItemLayout" class="text-outline">
          <a-button type="primary" html-type="submit" :disabled="loading">
            {{ $t("buttons.save") }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

const moment = require("moment-timezone");
export default {
  middleware: "authenticated",
  data() {
    return {
      monthVal: 0,
      dayVal: 0,
      yearVal: 0,
      confirmDirty: false,
      autoCompleteResult: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
      loading: false,
    };
  },
  computed: {
    getId() {
      return this.id;
    },
    getProfile() {
      // console.log(this.$store.getters['authCustom/profile'])
      return (
        this.profile || {
          email: "",
          firstname: "",
          secondname: "",
          dateofbirth: null,
        }
      );
    },
    ...mapGetters([
      "profile",
      "id",
      // ...
    ]),
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  async mounted() {
    await this.$store.dispatch("authCustom/authAction");

    const dateVals = this.getProfile.dateofbirth.split("-");
    this.monthVal = dateVals[1];
    this.dayVal = dateVals[2];
    this.yearVal = dateVals[0];
    // console.log(this.yearVal, this.monthVal, this.dayVal);
  },
  methods: {
    handleYearChange(val) {},
    handleMonthChange(val) {},
    handleDayChange(val) {},
    getTime(time) {
      return moment(time);
    },
    handleSubmit(e) {
      e.preventDefault();
      const id = this.getId;
      // const email = this.getProfile.email
      this.form.validateFields(async (err, values) => {
        if (!err) {
          // console.log(values)
          // console.log('Received values of form: ', values)
          const d = new Date(this.yearVal, this.monthVal - 1, this.dayVal);
          // console.log(this.yearVal, this.monthVal, this.dayVal, d);

          this.loading = true;
          const response = await this["authCustom/updateProfileAction"]({
            id,
            // email: values.email,
            password: values.password,
            firstname: values.firstname,
            secondname: values.secondname,
            gender: values.gender,
            dateofbirth: d, // values.dateofbirth
          });
          this.loading = false;
          // console.log('response: ' + response)
          if (!response) {
            this.$notification["error"]({
              message: this.$t("ui.ErrorOccurred"),
              description: false,
            });
          } else {
            this.$notification["success"]({
              message: this.$t("ui.SuccessOccurred"),
              description: false,
            });
            // this.$router.push(('/'))
          }
        }
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      // if (!value) { callback() }

      if (value && value !== form.getFieldValue("password")) {
        callback(this.$t("ui.passwordsInconsistent"));
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      // if (!value) { callback() }

      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
    ...mapActions(["authCustom/updateProfileAction"]),
  },
};
</script>

<style scoped>
.profile-form {
  margin: 20px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.subtitle {
  margin-bottom: 20px;
}

@media only screen and (max-width: 700px) {
  .dividerTitle {
    display: none;
  }
}
</style>
